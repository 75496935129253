<template>
  <div class="app-master">
    <div class="app-content">
      <Header />
      <main class="responsive-container">
        <slot></slot>
      </main>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="postcss">
.backend .app-footer {
  @apply bg-blue-800;
}

.frontend .app-footer {
  @apply bg-secondary-800;
}
</style>
