<template>
  <ol class="custom-dropdown--options w-[250px] right-0 left-auto top-[100%]">
    <li class="text-gray-400 !border-0 pointer-events-none">
      {{ $t('blockStructure.blockFormat.pleaseChoose') }}
    </li>
    <li class="cursor-pointer profile--button" @click="logoutClick">
      {{ $t('general.logout') }}
    </li>
    <li
      v-if="hasAdminAccess"
      class="cursor-pointer profile--button"
      @click="switchRoute"
    >
      <span>{{ switchText }}</span>
    </li>
  </ol>
</template>

<script>
import { logout } from '@/services/auth';

export default {
  name: 'ProfileDropdown',
  computed: {
    isCurrentRouteAdmin() {
      return this.$route.meta.requiredRoles?.includes('content_manager');
    },
    hasAdminAccess() {
      return this.$store.state.Auth.roles.some((role) =>
        ['Admin', 'content_manager'].includes(role)
      );
    },
    switchText() {
      if (this.isCurrentRouteAdmin) {
        return this.$t('header.goToAsaWebLex');
      }
      return this.$t('header.goToContentManager');
    },
  },
  methods: {
    closeDropdown() {
      this.$emit('close');
    },
    async logoutClick() {
      await logout();
      this.$store.dispatch('Auth/removeAuth');
      this.$router.push({ name: 'login' });
    },
    switchRoute() {
      if (this.isCurrentRouteAdmin) {
        return this.$router.push({ path: '/' });
      }
      return this.$router.push({ path: '/admin' });
    },
  },
};
</script>

<style lang="postcss" scoped>
.custom-dropdown--options {
  @apply !max-h-[400px];
  @apply !bg-gray-50;
  @apply !rounded;
  @apply pb-4 pt-2;
}

.profile--button {
  @apply cursor-pointer;
  @apply hover:!text-white;
}

.backend .profile--button {
  @apply text-blue-600;
}

.frontend .profile--button {
  @apply text-secondary-600;
}
</style>
