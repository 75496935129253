import { getCreators, getTypesOfLaw } from '@/services/document';

export default {
  namespaced: true,

  state: {
    elementReferences: [],
    categories: [],
    creators: [],
    typesOfLaw: [],
  },

  mutations: {
    setElementReferences(state, elementReferences) {
      state.elementReferences = elementReferences;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setCreators(state, creators) {
      state.creators = creators;
    },
    setTypesOfLaw(state, typesOfLaw) {
      state.typesOfLaw = typesOfLaw;
    },
  },
  actions: {
    async loadCreators({ commit }) {
      const creators = await getCreators();
      commit('setCreators', creators);
    },
    async loadTypesOfLaw({ commit }) {
      const typesOfLaw = await getTypesOfLaw();
      commit('setTypesOfLaw', typesOfLaw);
    },
  },
};
