import {
  getTypesOfLaw,
  getOriginsOfLaw,
  getCategories,
} from '@/services/document';
import i18n from '@/i18n';

export default {
  namespaced: true,

  state: {
    selectedFilters: {},
    availableFilters: [],
    isExactSearch: false,
  },

  mutations: {
    setSelectedFilters(state, selectedFilters) {
      state.selectedFilters = selectedFilters;
    },
    setAvailableFilters(state, availableFilters) {
      state.availableFilters = availableFilters;
    },
    setIsExactSearch(state, isExactSearch) {
      state.isExactSearch = isExactSearch;
    },
  },

  actions: {
    async loadAvailableFilters({ commit, rootState }) {
      const filters = [];

      filters.push(
        ...[
          {
            id: 'draft',
            type: 'state',
            name: i18n.t('document.states.draft'),
          },
          {
            id: 'is_not_yet_in_order',
            type: 'state',
            name: i18n.t('document.states.notYetInOrder'),
          },
          {
            id: 'is_in_order',
            type: 'state',
            name: i18n.t('document.states.inOrder'),
          },
          {
            id: 'is_out_of_order',
            type: 'state',
            name: i18n.t('document.states.notInOrder'),
          },
        ]
      );

      const promises = [];
      const types = ['typesOfLaw', 'originOfLaw', 'category'];

      promises.push(getTypesOfLaw());
      promises.push(getOriginsOfLaw());
      promises.push(getCategories());

      const results = await Promise.all(promises);

      results.forEach((result, index) => {
        if (index === 2) {
          const categories = result.filter((c) => c.isMainCategory);
          categories.forEach((category) => {
            filters.push({
              id: category.id,
              type: types[index],
              name: category.name,
            });
          });
          return;
        }
        result.forEach((entry) => {
          filters.push({ id: entry.id, type: types[index], name: entry.name });
        });
      });

      // Add creators to filters
      rootState.Block.creators.forEach((creator) => {
        filters.push({ id: creator.id, name: creator.name, type: 'creator' });
      });

      commit('setAvailableFilters', filters);
    },
  },
};
