import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './Auth.js';
import Block from './Block.js';
import Favorite from './Favorite.js';
import Loading from './Loading.js';
import Search from './Search.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Block,
    Loading,
    Favorite,
    Search,
  },
});
