<template>
  <header class="text-white app-header">
    <div class="header responsive-container">
      <router-link class="text-lg font-semibold no-underline" to="/">
        <svg-icon name="logo" class="app-logo" />
      </router-link>

      <div class="flex items-center">
        <language-switch class="xl:mr-12" />

        <div v-if="isLoggedIn" ref="profileDropdown" class="relative flex">
          <button class="header--link" @click="toggleProfileDropdown">
            {{ getName }}
            <svg-icon class="inline-block ml-3" name="user" />
          </button>
          <profile-dropdown v-if="showProfileDropdown" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SvgIcon from '@/components/SvgIcon.vue';
import LanguageSwitch from '@/components/LanguageSwitch.vue';
import ProfileDropdown from '@/components/ProfileDropdown.vue';

export default {
  components: {
    SvgIcon,
    LanguageSwitch,
    ProfileDropdown,
  },
  data() {
    return {
      showProfileDropdown: false,
    };
  },
  computed: {
    ...mapState('Auth', ['firstName', 'lastName', 'email']),
    ...mapGetters('Auth', ['isLoggedIn']),
    getName() {
      if (this.firstName && this.lastName) {
        return this.firstName + ' ' + this.lastName;
      }
      return this.email;
    },
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
    this.searchTerm = this.$route.query.term || '';
  },
  methods: {
    toggleProfileDropdown() {
      this.showProfileDropdown = true;
    },
    handleDocumentClick(e) {
      if (
        !this.$refs.profileDropdown ||
        this.$refs.profileDropdown.contains(e.target)
      ) {
        return;
      }
      this.showProfileDropdown = false;
    },
  },
};
</script>

<style lang="postcss" scoped>
.app-logo {
  @apply text-white;
  @apply h-[42px] w-[149px];
}
.app-header {
  @apply h-[4.5rem];
}

.backend .app-header {
  @apply bg-blue-800;
}

.frontend .app-header {
  @apply bg-secondary-800;
}

.header--link {
  @apply flex items-center;
  @apply text-white no-underline hover:underline hover:text-white;

  & svg {
    @apply xl:w-5 xl:h-5 h-10 w-10;
  }
}

.switch--button {
  @apply flex items-center;
  @apply mr-12;
  @apply text-white no-underline hover:underline hover:text-white;
}

.header {
  @apply h-full;
  @apply flex items-center justify-between;
  @apply px-10 4xl:px-0;
}
</style>
