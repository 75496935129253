<template>
  <div class="w-full">
    <div class="flex justify-between border-b border-gray-800 tabs--buttons">
      <button
        v-for="tab in tabs"
        :key="tab.title"
        class="tab-btn"
        :class="{
          'tab-btn--active': activeTab === tab.slotName,
          'font-medium': routeTabs,
        }"
        :disabled="disabledTabs.includes(tab.slotName)"
        @click="selectTab(tab.slotName)"
      >
        <p>{{ tab.title }}</p>
      </button>
    </div>
    <div v-if="!routeTabs" class="w-full mt-8">
      <slot name="header" />
      <slot v-if="destroyHiddenTabs" :name="activeTab"></slot>
      <div
        v-for="tab in tabs"
        v-show="tab.slotName === activeTab"
        v-else
        :key="tab.slotName"
      >
        <slot :name="tab.slotName" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    defaultTab: {
      type: String,
      required: false,
      default: '',
    },
    disabledTabs: {
      type: Array,
      default: () => [],
    },
    // This property can be used to force a v-show tab functionality
    destroyHiddenTabs: {
      type: Boolean,
      default: true,
    },
    routeTabs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: null,
    };
  },
  computed: {
    activeTabWrapper: {
      get() {
        return this.activeTab;
      },
      set(newVal) {
        this.$emit('setActiveTab', newVal, this.activeTab === null);
        this.activeTab = newVal;
      },
    },
  },
  mounted() {
    if (this.defaultTab) {
      this.activeTabWrapper = this.defaultTab;
    } else if (this.tabs[0]) {
      this.activeTabWrapper = this.tabs[0].slotName;
    }
  },
  methods: {
    selectTab(slotName) {
      this.activeTabWrapper = slotName;
    },
  },
};
</script>

<style scoped lang="postcss">
.tab-btn {
  @apply mr-4 pb-2;
  @apply cursor-pointer;
  @apply border-b-4 border-transparent;

  & p {
    @apply text-base;
  }

  &.tab-btn--active {
    @apply border-gray-800;
  }

  &:disabled {
    @apply !text-gray-300;
    @apply cursor-not-allowed;
  }

  &:last-of-type {
    @apply mr-0;
  }
}

.backend .tab-btn {
  @apply text-blue-600;

  &.tab-btn--active p {
    @apply text-blue-800;
  }
}

.frontend .tab-btn {
  @apply text-secondary-600;

  &.tab-btn--active p {
    @apply text-secondary-800;
  }
}
</style>
