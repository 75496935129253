<template>
  <div class="h-[72px] bg-gray-100 w-full border-gray-800 border-b">
    <tabs
      :tabs="tabs"
      class="flex items-end w-full h-full router-tabs responsive-container"
      :default-tab="currentRouteTab"
      :route-tabs="true"
      @setActiveTab="setActiveTab"
    />
  </div>
</template>

<script>
import Tabs from '@/components/Tabs.vue';

export default {
  components: {
    Tabs,
  },
  computed: {
    tabs() {
      const homepage = {
        title: this.$t('user.tabs.homepage'),
        slotName: '0',
      };
      const content = {
        title: this.$t('user.tabs.content'),
        slotName: '5',
      };
      const allContent = {
        title: this.$t('user.tabs.allContent'),
        slotName: '4',
      };
      const favorites = {
        title: this.$t('user.tabs.favorites'),
        slotName: '1',
      };
      const notifications = {
        title: this.$t('user.tabs.notifications'),
        slotName: '2',
      };
      const accounts = {
        title: this.$t('user.tabs.accounts'),
        slotName: '3',
      };
      const tabsArr = [homepage, content, allContent, favorites, notifications];

      if (this.$store.state.Auth.isOrgUnitAdmin) {
        tabsArr.push(accounts);
      }

      return tabsArr;
    },
    currentRouteTab() {
      return this.$route.meta.tabSlot;
    },
  },
  methods: {
    setActiveTab(tab, initial) {
      if (initial) {
        return;
      }
      if (tab === '0') {
        this.$router.push('/');
      } else if (tab === '1') {
        this.$router.push('/favorites');
      } else if (tab === '2') {
        this.$router.push('/notifications');
      } else if (tab === '3') {
        this.$router.push('/accounts');
      } else if (tab === '4') {
        this.$router.push('/all');
      } else if (tab === '5') {
        this.$router.push('/search');
      }
    },
  },
};
</script>

<style lang="postcss">
.router-tabs .tabs--buttons {
  @apply h-full w-full items-end justify-start;
  @apply space-x-16 px-10;
  @apply 4xl:px-0;
  @apply border-0;
}
</style>
