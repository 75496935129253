<template>
  <single-select
    v-model="currentLocale"
    name="languageSelect"
    :options="availableLocales"
    option-lookup="id"
    display-lookup="name"
  />
</template>

<script>
import SingleSelect from '@/components/SingleSelect.vue';
import EditorEventBus from '@/helpers/EditorEventBus';
import { setLanguage } from '@/services/language.service';

export default {
  components: {
    SingleSelect,
  },
  computed: {
    currentLocale: {
      get() {
        return this.$i18n.locale;
      },
      set(value) {
        setLanguage(value);
        EditorEventBus.$emit('reloadDocumentList');
        EditorEventBus.$emit('reloadSearch');
        EditorEventBus.$emit('reloadFavorites');
        EditorEventBus.$emit('reloadDashboard');
        EditorEventBus.$emit('reloadNotifications');
      },
    },
    availableLocales() {
      return [
        { id: 'de', name: 'Deutsch' },
        { id: 'fr', name: 'Français' },
        { id: 'it', name: 'Italiano' },
      ];
    },
  },
};
</script>

<style lang="postcss" scoped>
/deep/ select {
  @apply w-[140px] min-w-[140px];
  @apply bg-transparent;
  @apply border-0;
  @apply focus:outline-none focus:shadow-none;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 0.5L8 7.5L1 0.5' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
</style>
