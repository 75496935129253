import Vue from 'vue';
import VuejsDialog from 'vuejs-dialog';

import Confirmation from '@/components/Confirmation.vue';
import DeleteAlert from '@/components/admin/structuring/DeleteAlert.vue';

import 'vuejs-dialog/dist/vuejs-dialog.min.css';

Vue.use(VuejsDialog, {
  view: 'confirmation',
});

Vue.dialog.registerComponent('confirmation', Confirmation);
Vue.dialog.registerComponent('DeleteAlert', DeleteAlert);
