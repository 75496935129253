import { getFavorites } from '@/services/Favorite.service';

export default {
  namespaced: true,

  state: {
    favorites: {},
  },

  mutations: {
    setFavorites(state, favorites) {
      state.favorites = favorites;
    },
  },
  actions: {
    async loadFavorites({ commit }) {
      const languages = ['de', 'fr', 'it', 'en'];
      const promises = [];

      languages.forEach((language) => {
        promises.push(getFavorites(language));
      });

      const responses = await Promise.allSettled(promises);

      const favorites = {};

      responses.forEach((response, index) => {
        favorites[languages[index]] = response.value;
      });

      commit('setFavorites', favorites);
    },
  },
};
