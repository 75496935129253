<template>
  <div id="app" class="app-master">
    <router-view />
    <notification />
  </div>
</template>

<script>
import Notification from '@/components/Notification';
import { setLanguage } from '@/services/language.service';

const LANGUAGES = ['de', 'fr', 'it'];

export default {
  components: {
    Notification,
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || 'asaWebLex';
    },
  },
  created() {
    const savedLanguage = localStorage.getItem('lang');
    // Get default browser language code.
    let browserLanguage = navigator.language.split('-')[0];

    // If no language is saved in the localstorage and the default browser language is not the same as the app language,
    // Change it to the browser language.
    if (
      !localStorage.getItem('lang') &&
      browserLanguage !== this.$i18n.locale
    ) {
      if (!LANGUAGES.includes(browserLanguage)) {
        browserLanguage = 'de';
      }

      setLanguage(browserLanguage);
    } else if (savedLanguage !== this.$i18n.locale && savedLanguage !== null) {
      setLanguage(savedLanguage);
    } else {
      document.documentElement.setAttribute('lang', this.$i18n.locale);
    }
  },
};
</script>

<style lang="postcss">
.app-master {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: #f7f7f7;
}
</style>
