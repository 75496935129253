export default {
  namespaced: true,

  state: {
    /**
     * hydrate the state from the session if it exists otherwise null it
     */
    firstName: null,
    lastName: null,
    email: null,
    roles: [],
    permissions: [],
    isTwoFactorActive: false,
    isOrgUnitAdmin: false,
  },

  getters: {
    /**
     * Whether the user is logged in
     * @param {object} state Module state
     */
    isLoggedIn(state) {
      return state.email !== null;
    },
  },

  mutations: {
    /**
     * Remove the login information and effectively log the user out
     * @param {object} state Module state
     */
    removeAuth(state) {
      state.firstName = null;
      state.lastName = null;
      state.email = null;
      state.isTwoFactorActive = false;
      state.roles = [];
      state.permissions = [];
      state.isOrgUnitAdmin = false;
    },

    setUser(state, user) {
      state.email = user.email;
      state.roles = user.roles;
      state.permissions = user.permissions;
      state.firstName = user.firstName;
      state.lastName = user.lastName;
      state.isTwoFactorActive = user.isTwoFactorActive;
      state.isOrgUnitAdmin = user.isOrgUnitAdmin;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    removeAuth({ commit }) {
      commit('removeAuth');
    },
  },
};
