import Vue from 'vue';

import DefaultLayout from '@/layouts/DefaultLayout';
import EmptyLayout from '@/layouts/EmptyLayout';
import DocumentLayout from '@/layouts/DocumentLayout.vue';
import AdminLayout from '@/layouts/AdminLayout.vue';

Vue.component('DefaultLayout', DefaultLayout);
Vue.component('EmptyLayout', EmptyLayout);
Vue.component('DocumentLayout', DocumentLayout);
Vue.component('AdminLayout', AdminLayout);
