/**
 * Unpack the API data and log errors on development
 * @param {Promise} request
 * @return {Promise<*>}
 */
export const unpack = async (request) => {
  try {
    const { data } = await request;
    return data;
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.log(e);
    }
    throw e;
  }
};

export const getSortState = (sortState) => {
  switch (sortState) {
    case 1:
      return 'title';
    case 2:
      return '-title';
    default:
      return '';
  }
};
