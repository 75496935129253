<template>
  <div class="app-master">
    <div class="app-content">
      <Header />
      <UserNavigation />
      <slot></slot>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import UserNavigation from '@/components/UserNavigation.vue';

export default {
  components: {
    Header,
    UserNavigation,
    Footer,
  },
};
</script>

<style lang="postcss">
.frontend .app-footer {
  @apply bg-secondary-800;
}
</style>
