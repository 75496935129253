<template>
  <div>
    <div class="px-16 py-12 dg-content">
      <div class="flex justify-between mb-8">
        <h2 class="message-title">{{ messageTitle }}</h2>
        <button class="flex" @click="handleDismiss">
          <svg-icon class="svg-icon--lg" name="close" />
        </button>
      </div>

      <div class="my-10">
        <p
          v-if="messageType === 'text'"
          class="mb-4 text-sm italic break-words"
        >
          {{ messageBody.content }}
        </p>
        <p class="mt-2 text-sm">{{ messageBody.note }}</p>
      </div>
    </div>
    <div class="flex justify-between px-16 pt-10 pb-12 footer--shadow">
      <button class="px-5 py-3 mr-8 btn btn--secondary" @click="handleDismiss">
        {{ cancelText }}
      </button>
      <button class="px-5 py-3 btn btn--primary" @click="handleOk">
        {{ confirmText }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';
// $t is not usable inside the vue component so we want to import our own i18n instance
import i18n from '@/i18n';

export default {
  mixins: [DialogMixin],
  computed: {
    cancelText() {
      if (this.options.cancelText !== 'Close') return this.options.cancelText;
      return i18n.t('general.no');
    },
    confirmText() {
      if (this.options.okText !== 'Continue') return this.options.okText;
      return i18n.t('general.yes');
    },
    messageType() {
      return this.options.message.type;
    },
  },
  methods: {
    handleOk() {
      this.proceed();
    },
    handleDismiss() {
      this.cancel();
    },
  },
};
</script>
<style lang="postcss">
.dg-main-content {
  max-width: 40.625rem !important;
  @apply !p-0;
  .dg-content {
    button {
      align-self: flex-start !important;
    }
  }
}

.dg-container .dg-content-cont--floating {
  @apply top-0;
  transform: translateY(30%);

  @media screen and (height < 800px) {
    transform: translateY(0%);
  }
}

.dg-backdrop {
  @apply opacity-50 !important;
}

.frontend .dg-backdrop {
  @apply !bg-secondary-800;
}

.backend .dg-backdrop {
  @apply !bg-blue-800;
}

.message-title {
  @apply text-4xl;
  line-height: 2.5rem !important;
}

.dg-backdrop-enter-active {
  animation: none !important;
}

.dg-backdrop-leave-active {
  animation: none !important;
}

.footer--shadow {
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.25);
}
</style>
