/**
 * Converts a number counter to a letter counter
 * @param {*} The counter as a number
 * @returns The counter in letters e.g. c for 3
 */
const getLetterFromCounter = (counter) => {
  const modulos = counter % 26;
  // After the z we want to start with aa, ab, ac... So we calculate how many iterations we need
  let neededIterations = (counter / 26) | 0;
  // Get the letter with the help of ASCII (if the counter is 26 we just return z and decrese the needed interactions)
  const letters = modulos
    ? String.fromCharCode(96 + modulos)
    : (--neededIterations, 'z');

  return neededIterations
    ? getLetterFromCounter(neededIterations) + letters
    : letters;
};

/**
 * Converts the block tree to a flat list
 * @param {*} rootBlock The first block of the tree
 * @returns The tree as a list
 */
const flattenTheTree = (rootBlock) => {
  const block = rootBlock;
  // We can add the first block straight away
  const blocks = [{ ...block, depth: 0 }];

  let index = 1;

  const typesAboveParagraph = [
    'part',
    'title',
    'chapter',
    'section',
    'article',
  ];

  const walkThroughBlocks = (block, depth) => {
    let letterCounter = 0;
    let digitCounter = 0;
    let paragraphCounter = 0;

    // Reset the listDeepness for all blocks where it is not needed
    if (
      block.htmlElementReference !== 'letter' &&
      block.htmlElementReference !== 'digit'
    ) {
      block.listDeepness = 0;
    }

    if (block.children) {
      let childIndex = 1;
      for (let i = 0; i < block.children.length; i++) {
        const child = block.children[i];

        if (child.htmlElementReference !== 'num_paragraph') {
          paragraphCounter = 0;
        }

        // We want to check for letters and digits to save the counter value
        if (child.htmlElementReference === 'letter') {
          letterCounter++;
          child.counter = getLetterFromCounter(letterCounter);
          child.listDeepness = block.listDeepness ? block.listDeepness + 1 : 1;
        } else if (child.htmlElementReference === 'digit') {
          digitCounter++;
          child.counter = digitCounter;
          child.listDeepness = block.listDeepness ? block.listDeepness + 1 : 1;
        } else if (child.htmlElementReference === 'num_paragraph') {
          paragraphCounter++;
          child.counter = paragraphCounter;
        } else if (child.htmlElementReference === 'list') {
          child.listDeepness = block.listDeepness ? block.listDeepness + 1 : 1;
        }

        // Save some indices for handling the disabling of move blocks
        child.index = index;
        child.indexOfChildrens = childIndex;
        child.isFirstChild = childIndex === 1;
        child.isLastChild = i === block.children.length - 1;

        index++;
        childIndex++;

        blocks.push({ ...block.children[i], depth });
        walkThroughBlocks(block.children[i], depth + 1);
      }
    }
  };

  walkThroughBlocks(rootBlock, 1);
  return blocks;
};

/**
 * This function is used to make a hierarchical tree out of a flat list of elements.
 * Each element in the list needs to have a parent attribute, containing the id of the parent.
 * This attribute is then used to find the parent and add the element as children to this parent.
 **/
const createTreeFromList = (list) => {
  let treeList = [];
  let lookup = {};
  list.forEach((obj) => {
    lookup[obj.id] = obj;
    obj.children = [];
  });
  list.forEach((obj) => {
    if (obj.parent !== null) {
      lookup[obj.parent].children.push(obj);
    } else {
      treeList.push(obj);
    }
  });
  return treeList;
};

export { flattenTheTree, getLetterFromCounter, createTreeFromList };
