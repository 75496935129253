<template>
  <div class="relative p-4 dg-content w-[32rem]">
    <p class="font-semibold">
      {{ blockCantBeDeletedText }}
    </p>
    <p class="mt-4">{{ followingLinksText }}</p>
    <div v-for="link in conflictingLinks" :key="link.id" class="mt-2">
      <a :href="`/admin/${link.source}`">{{ link.text }}</a>
    </div>

    <button class="mt-6 btn btn--primary" @click="handleDismiss">
      {{ closeText }}
    </button>
  </div>
</template>

<script lang="ts">
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';
// $t is not usable inside the vue component so we want to import our own i18n instance
import i18n from '@/i18n';

export default {
  mixins: [DialogMixin],
  computed: {
    conflictingLinks() {
      return this.options.conflictingLinks;
    },
    blockCantBeDeletedText() {
      return i18n.t('blockStructure.blockDelete.blockCantBeDeleted');
    },
    followingLinksText() {
      return i18n.t('blockStructure.blockDelete.followingLinks');
    },
    closeText() {
      return i18n.t('general.close');
    },
  },
  methods: {
    handleDismiss() {
      this.cancel();
    },
  },
};
</script>

<style lang="postcss">
.delete-alert .dg-main-content {
  @apply max-w-lg;
}
</style>
