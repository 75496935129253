<template>
  <div>
    <select
      v-model="selectedValue"
      :name="name"
      class="form-input w-full cursor-pointer"
      :class="selectClasses"
    >
      <option
        class="placeholder"
        value=""
        :disabled="placeholderDisabled"
        selected
      >
        {{ $t('upload.uploadForm.placeholders.select') }}
      </option>
      <option
        v-for="(option, index) in options"
        :key="`${name}--${getDisplayValue(option)}---${index}`"
        :value="getOptionValue(option)"
      >
        {{ getDisplayValue(option) }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SingleSelect',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: null,
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    optionLookup: {
      type: String,
      default: '',
    },
    displayLookup: {
      type: String,
      default: '',
    },
    placeholderDisabled: {
      type: Boolean,
      default: true,
    },
    selectClasses: {
      type: String,
      default: '',
    }
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
  methods: {
    getDisplayValue(option) {
      if (this.displayLookup) return option[this.displayLookup];
      return option;
    },
    getOptionValue(option) {
      if (this.optionLookup) return option[this.optionLookup];
      return option;
    },
  },
};
</script>

<style lang="postcss" scoped>
select {
  & option {
    @apply text-blue-600;
  }
}
</style>
