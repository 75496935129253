<template>
  <div class="h-[72px] bg-gray-100 w-full border-gray-800 border-b">
    <tabs
      :tabs="tabs"
      class="flex items-end w-full h-full router-tabs responsive-container"
      :default-tab="currentRouteTab"
      :route-tabs="true"
      @setActiveTab="setActiveTab"
    />
  </div>
</template>

<script>
import Tabs from '@/components/Tabs.vue';

export default {
  components: {
    Tabs,
  },
  computed: {
    tabs() {
      const content = {
        title: this.$t('editor.navigationTabs.content'),
        slotName: '0',
      };
      /*const news = {
        title: this.$t('editor.navigationTabs.news'),
        slotName: '1',
      };*/
      return [content /*, news*/];
    },
    currentRouteTab() {
      return this.$route.meta.tabSlot;
    },
  },
  methods: {
    setActiveTab(tab, initial) {
      if (initial) {
        return;
      }
      if (tab === '0') {
        this.$router.push('/admin');
      } else {
        this.$router.push('/news');
      }
    },
  },
};
</script>

<style lang="postcss">
.backend .router-tabs .tabs--buttons {
  @apply h-full w-full items-end justify-start;
  @apply space-x-16 px-10 4xl:px-0;
}
</style>
