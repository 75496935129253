export default {
  namespaced: true,
  state: {
    loadingList: [],
  },
  getters: {
    loading(state) {
      return state.loadingList.length !== 0;
    },
  },
  mutations: {
    addLoadingState(state) {
      state.loadingList.push('loading');
    },
    removeLoadingState(state) {
      state.loadingList.pop();
    },
    resetLoadingState(state) {
      state.loadingList = [];
    },
  },
};
