import { userApi } from './api';

const addFavorite = async (payload) => {
  const response = await userApi.post(`favorite/`, payload);
  return response.data;
};

const deleteFavorite = async (id) => {
  const response = await userApi.delete(`favorite/${id}/`);
  return response.data;
};

const getFavorites = async (lang) => {
  let url = 'favorite/';

  if (lang) {
    url += `?language=${lang}`;
  }
  const response = await userApi.get(url);
  return response.data;
};

export { addFavorite, deleteFavorite, getFavorites };
