<template>
  <notifications
    position="top center"
    class="min-w-128"
    :duration="duration"
    :close-on-click="true"
    :ignore-duplicates="true"
  >
    <template slot="body" slot-scope="props">
      <div class="notification" :class="getClass(props.item.type)">
        <svg-icon
          v-if="!isNoticeType(props.item.type)"
          :name="getIcon(props.item.type)"
          class="mt-1 mr-3 icon-md"
          :class="getColorClass(props.item.type)"
        />
        <div class="flex-1">
          <span v-if="props.item.text">
            {{ props.item.text }}
          </span>
          <slot></slot>
        </div>
        <div class="cursor-pointer" @click="props.close">
          <svg-icon class="svg-icon--md" name="close" />
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
const ERRORTYPE = 'error';
const NOTICETYPE = 'notice';

export default {
  props: {
    duration: {
      type: Number,
      default: 20000,
    },
  },
  methods: {
    getIcon(type) {
      return type === ERRORTYPE ? 'warning-circle' : 'done-circle';
    },
    getClass(type) {
      switch (type) {
        case ERRORTYPE:
          return 'is-error';
        case NOTICETYPE:
          return 'is-notice';
        default:
          return 'is-success';
      }
    },
    getColorClass(type) {
      return type === ERRORTYPE ? 'text-red-600' : 'text-blue-500';
    },
    isNoticeType(type) {
      return type === NOTICETYPE;
    },
  },
};
</script>
