const Login = () => import('@/views/Login.vue');
const Dashboard = () => import('@/views/Dashboard.vue');
const Profile = () => import('@/views/Profile.vue');
const ForgotPassword = () => import('@/views/ForgotPassword.vue');
const PasswordReset = () => import('@/views/PasswordReset.vue');
const Admin = () => import('@/views/Admin.vue');
const AdminEditor = () => import('@/views/AdminEditor.vue');
const DocumentDetail = () => import('@/views/DocumentDetail.vue');
const Favorite = () => import('@/views/Favorite.vue');
const SearchResult = () => import('@/views/SearchResult.vue');
const Notifications = () => import('@/views/Notifications.vue');
const Imprint = () => import('@/views/Imprint.vue');
const Accounts = () => import('@/views/Accounts.vue');
const AllContent = () => import('@/views/AllContent.vue');

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_profile_page',
      noPermissionRedirectTo: 'dashboard',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset,
  },
  {
    path: '/document/:id/:blockId?',
    name: 'documentDetail',
    component: DocumentDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/search',
    name: 'searchResult',
    component: SearchResult,
    meta: {
      requiresAuth: true,
      tabSlot: '5',
    },
  },
  {
    path: '/all',
    name: 'allContent',
    component: AllContent,
    meta: {
      requiresAuth: true,
      tabSlot: '4',
    },
  },
  {
    path: '/favorites/',
    name: 'favorites',
    component: Favorite,
    meta: {
      requiresAuth: true,
      tabSlot: '1',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      requiresAuth: true,
      tabSlot: '2',
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      requiresAuth: true,
      requiredRoles: ['Admin', 'content_manager'],
      noPermissionRedirectTo: 'dashboard',
      tabSlot: '0',
    },
  },
  {
    path: '/admin/:id',
    name: 'adminEditor',
    component: AdminEditor,
    meta: {
      requiresAuth: true,
      requiredRoles: ['Admin', 'content_manager'],
      noPermissionRedirectTo: 'dashboard',
      tabSlot: '0',
    },
  },
  {
    path: '/admin/news',
    name: 'adminNews',
    component: AdminEditor,
    meta: {
      requiresAuth: true,
      tabSlot: '1',
    },
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: Imprint,
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: Accounts,
    meta: {
      requiresAuth: true,
      requiresOrgUnitAdmin: true,
      noPermissionRedirectTo: 'dashboard',
      tabSlot: '3',
    },
  },
  {
    path: '/link/:id',
    name: 'link',
    component: () => null,
    meta: {
      requiresAuth: true,
    },
  },
];
