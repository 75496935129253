<template>
  <div class="app-master">
    <div class="app-content">
      <Header />
      <AdminNavigation />
      <main class="px-10 responsive-container 4xl:px-0">
        <slot></slot>
      </main>
    </div>

    <footer class="py-6 text-white bg-blue-800">
      <div class="container">
        <p class="text-lg font-semibold">{{ $t('general.footerText') }}</p>
      </div>
    </footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import AdminNavigation from '@/components/admin/AdminNavigation.vue';

export default {
  components: {
    Header,
    AdminNavigation,
  },
};
</script>
