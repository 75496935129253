<template>
  <footer>
    <div class="bg-secondary-800 h-[80px] text-white">
      <div
        class="flex items-center justify-end w-full h-full px-10  responsive-container 4xl:px-0"
      >
        <a
          href="mailto:asaweblex@asa.ch
"
          class="mr-2 green-link"
        >
          {{ $t('footer.contact') }}
        </a>
        |
        <router-link to="imprint" class="ml-2 green-link">
          {{ $t('footer.imprint') }}
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="postcss" scoped>
.green-link {
  @apply !text-white hover:!text-gray-200 no-underline;
}

.gray-link {
  @apply !text-secondary-800;
}
</style>
